import React from 'react';
import { AppBar, Toolbar, Button, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import PersonIcon from '@mui/icons-material/Person';

const GradientAppBar = styled(AppBar)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.dark} 90%)`,
  height: '80px',
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
}));

const Logo = styled('img')({
  height: '5.5vh',
  marginRight: '10px',
});

const HeaderButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  fontSize: '1rem',
  fontWeight: 500,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const AnimatedHeaderButton = motion(HeaderButton);

function Header({ onLogout }) {
  return (
    <GradientAppBar position="static">
      <Toolbar sx={{ 
        justifyContent: 'space-between', 
        height: '100%',
        alignItems: 'center',
        padding: { xs: '0 16px', sm: '0 24px' },
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Logo src={`${window.location.origin}/logo_white.png`} alt="BIM Logo" />
        </Box>
        <Box sx={{ 
          display: 'flex',
          height: '100%',
          alignItems: 'center'
        }}>
          <AnimatedHeaderButton
            color="inherit"
            onClick={onLogout}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            startIcon={<PersonIcon />}
          >
            Logout
          </AnimatedHeaderButton>
        </Box>
      </Toolbar>
    </GradientAppBar>
  );
}

export default Header;