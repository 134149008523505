import React, { useState, useEffect, useCallback } from 'react';
import { FaPlus, FaComments, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { collection, query, getDocs } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { Button, List, ListItem, ListItemIcon, ListItemText, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import SessionCreationDialog from './SessionCreationDialog';

const SidebarContainer = styled(Box)(({ theme, isexpanded }) => ({
  display: 'flex',
  height: '100%',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  width: isexpanded === 'true' ? '320px' : '20px',
  position: 'relative',
  overflowX: 'hidden',
  backgroundColor: theme.palette.background.default,
  zIndex: 1100,
}));

const SidebarContent = styled(Box)(({ theme }) => ({
  width: '300px',
  overflowY: 'auto',
  overflowX: 'hidden',
  height: '100%',
}));

const ToggleButton = styled(Box)(({ theme }) => ({
  width: '20px',
  cursor: 'pointer',
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.common.black,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: theme.palette.grey[400],
  },
}));

function Sidebar({ onSessionSelect, showNotification, currentSession, onCreateSession }) {
  const [sessions, setSessions] = useState([]);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const fetchSessions = useCallback(async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }
      const sessionsRef = collection(db, `users/${user.uid}/sessions`);
      const q = query(sessionsRef);
      const querySnapshot = await getDocs(q);
      const fetchedSessions = querySnapshot.docs.map(doc => ({
        session_id: doc.id,
        ...doc.data()
      }));
      console.log('Fetched sessions:', fetchedSessions);
      setSessions(fetchedSessions);
    } catch (error) {
      console.error('Error fetching sessions:', error);
      showNotification('Failed to fetch sessions', 'error');
      setSessions([]);
    }
  }, [showNotification]);

  useEffect(() => {
    fetchSessions();
  }, [fetchSessions]);

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleCreateSession = (sessionName, responseType, customInstructions) => {
    onCreateSession(sessionName, responseType, customInstructions);
    setIsDialogOpen(false);
  };

  return (
    <SidebarContainer isexpanded={isExpanded.toString()}>
      {isExpanded && (
        <SidebarContent>
          <Button
            variant="contained"
            color="primary"
            startIcon={<FaPlus />}
            onClick={handleOpenDialog}
            sx={{ 
              margin: 2, 
              width: 'calc(100% - 32px)',
              maxWidth: '250px'
            }}
          >
            New Session
          </Button>
          <List>
            {sessions.map(session => (
              <ListItem
                button
                key={session.session_id}
                onClick={() => onSessionSelect(session)}
                selected={currentSession && currentSession.session_id === session.session_id}
              >
                <ListItemIcon>
                  <FaComments />
                </ListItemIcon>
                <ListItemText primary={session.session_name || session.session_id} />
              </ListItem>
            ))}
          </List>
        </SidebarContent>
      )}
      <ToggleButton onClick={toggleSidebar}>
        {isExpanded ? <FaChevronLeft /> : <FaChevronRight />}
      </ToggleButton>
      <SessionCreationDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onCreate={handleCreateSession}
      />
    </SidebarContainer>
  );
}

export default Sidebar;
