import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Select, MenuItem } from '@mui/material';

function SessionCreationDialog({ open, onClose, onCreate }) {
  const [sessionName, setSessionName] = useState('');
  const [responseType, setResponseType] = useState('fast');
  const [customInstructions, setCustomInstructions] = useState('');

  const handleCreate = () => {
    if (!sessionName.trim()) {
      alert('Session Name is required');
      return;
    }
    onCreate(sessionName, responseType, customInstructions);
    setSessionName('');
    setResponseType('fast');
    setCustomInstructions('');
  };

  const handleCancel = () => {
    onClose();
    setSessionName('');
    setResponseType('fast');
    setCustomInstructions('');
  };

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>Create New Session</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Session Name"
          type="text"
          fullWidth
          value={sessionName}
          onChange={(e) => setSessionName(e.target.value)}
        />
        <Select
          margin="dense"
          label="Response Type"
          fullWidth
          value={responseType}
          onChange={(e) => setResponseType(e.target.value)}
        >
          <MenuItem value="fast">Fast</MenuItem>
          <MenuItem value="detailed">Detailed</MenuItem>
          <MenuItem value="report">Report</MenuItem>
        </Select>
        <TextField
          margin="dense"
          label="Custom Instructions"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={customInstructions}
          onChange={(e) => setCustomInstructions(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleCreate} color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SessionCreationDialog;
