import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, doc, setDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import Sidebar from './Sidebar';
import Header from './Header';
import ChatSection from './ChatSection';
import DocumentSection from './DocumentSection';
import Notification from './Notification';
import { auth, db } from '../firebase';
import { Box, Typography, ThemeProvider, CssBaseline } from '@mui/material';
import theme from '../theme';

function MainUI() {
  const [currentSession, setCurrentSession] = useState(() => {
    const lastSessionData = localStorage.getItem('lastSession');
    if (lastSessionData) {
      try {
        // Try to parse as JSON (new format)
        return JSON.parse(lastSessionData);
      } catch (e) {
        // If parsing fails, it's in old format (UUID string)
        return { session_id: lastSessionData, is_new: false };
      }
    }
    return null;
  });
  const [isLoading, setIsLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setIsLoading(false);
      } else {
        navigate('/');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleLogout = async () => {
    if (window.confirm('Are you sure you want to log out?')) {
      try {
        await auth.signOut();
        localStorage.removeItem('lastSession');
        navigate('/');
      } catch (error) {
        console.error('Error signing out:', error);
        showNotification('Failed to log out', 'error');
      }
    }
  };

  const handleSessionSelect = (session) => {
    console.log('Session selected:', session);
    setCurrentSession(session);
    localStorage.setItem('lastSession', JSON.stringify(session));
  };

  const showNotification = (message, type = 'info') => {
    const id = Date.now();
    setNotifications((prev) => [...prev, { id, message, type }]);
  };

  const removeNotification = (id) => {
    setNotifications((prev) => prev.filter((notif) => notif.id !== id));
  };

  const handleCreateSession = async (sessionName, responseType, customInstructions) => {
    if (!user) {
      showNotification('User not authenticated', 'error');
      return;
    }

    const newSessionId = uuidv4();
    const newSession = {
      session_id: newSessionId,
      session_name: sessionName,
      response_type: responseType,
      custom_instructions: customInstructions,
      is_new: true,
    };

    try {
      const sessionsRef = collection(db, `users/${user.uid}/sessions`);
      await setDoc(doc(sessionsRef, newSessionId), {
        session_name: sessionName,
        response_type: responseType,
        custom_instructions: customInstructions,
        created_at: new Date().toISOString(),
      });

      setCurrentSession(newSession);
      localStorage.setItem('lastSession', JSON.stringify(newSession));
      showNotification('New session created', 'success');
    } catch (error) {
      console.error('Error creating new session:', error);
      showNotification('Failed to create new session', 'error');
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Header onLogout={handleLogout} />
        <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden', height: 'calc(100vh - 80px)' }}>
          <Sidebar
            onSessionSelect={handleSessionSelect}
            showNotification={showNotification}
            currentSession={currentSession}
            onCreateSession={handleCreateSession}
          />
          <Box sx={{ flexGrow: 1, p: 3, overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
            {currentSession ? (
              <>
                <ChatSection session={currentSession} showNotification={showNotification} />
                <DocumentSection session={currentSession} showNotification={showNotification} />
              </>
            ) : (
              <Typography sx={{ textAlign: 'center', mt: 4 }}>
                Select a session or create a new session to begin
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={{ position: 'fixed', top: 80, right: 20, zIndex: 1000 }}>
          {notifications.map((notif) => (
            <Notification
              key={notif.id}
              message={notif.message}
              type={notif.type}
              onClose={() => removeNotification(notif.id)}
            />
          ))}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default MainUI;
