import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyD_wfScDW9vE0N88UDotp2vnwNRiAK6Mow",
    authDomain: "bimbackend-dbe70.firebaseapp.com",
    projectId: "bimbackend-dbe70",
    storageBucket: "bimbackend-dbe70.appspot.com",
    messagingSenderId: "552076687840",
    appId: "1:552076687840:web:18df4db9baf5ed8f6f1385",
    measurementId: "G-J7R351GBVE"
  };

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);