import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, LinearProgress, Chip } from '@mui/material';
import { Delete, Upload } from '@mui/icons-material';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { collection, addDoc, deleteDoc, query, getDocs, doc, onSnapshot } from 'firebase/firestore';
import { storage, db, auth } from '../firebase';

function DocumentSection({ session, showNotification }) {
  const [documents, setDocuments] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [isUploading, setIsUploading] = useState(false);

  const fetchDocuments = useCallback(async () => {
    if (!session) return;
    try {
      const user = auth.currentUser;
      const q = query(collection(db, `users/${user.uid}/sessions/${session.session_id}/documents`));
      const querySnapshot = await getDocs(q);
      const docs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setDocuments(docs);
    } catch (error) {
      console.error('Error fetching documents:', error);
      showNotification('Failed to fetch documents', 'error');
    }
  }, [session, showNotification]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  useEffect(() => {
    if (!session) return;

    const user = auth.currentUser;
    const q = query(collection(db, `users/${user.uid}/sessions/${session.session_id}/documents`));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const updatedDocs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setDocuments(updatedDocs);
    });

    return () => unsubscribe();
  }, [session]);

  const handleFileUpload = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) return;

    setIsUploading(true);
    setUploadProgress({});

    const user = auth.currentUser;
    const uploadPromises = files.map(file => {
      const storageRef = ref(storage, `users/${user.uid}/sessions/${session.session_id}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on('state_changed', (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(prev => ({ ...prev, [file.name]: progress }));
      });

      return uploadTask.then(async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        await addDoc(collection(db, `users/${user.uid}/sessions/${session.session_id}/documents`), {
          name: file.name,
          url: downloadURL,
          timestamp: new Date(),
          ingestion_status: "pending",
          prefix: `users/${user.uid}/sessions/${session.session_id}/${file.name}`
        });
      });
    });

    try {
      await Promise.all(uploadPromises);
      showNotification('All documents uploaded successfully', 'success');
    } catch (error) {
      console.error('Error uploading documents:', error);
      showNotification('Failed to upload some documents', 'error');
    } finally {
      setIsUploading(false);
      setUploadProgress({});
    }
  };

  const handleDeleteDocument = async (document) => {
    try {
      const user = auth.currentUser;
      await deleteObject(ref(storage, document.prefix));
      await deleteDoc(doc(db, `users/${user.uid}/sessions/${session.session_id}/documents`, document.id));
      showNotification('Document deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting document:', error);
      showNotification('Failed to delete document', 'error');
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'default';
      case 'ingesting':
        return 'primary';
      case 'completed':
        return 'success';
      case 'failed':
        return 'error';
      default:
        return 'default';
    }
  };

  const calculateOverallProgress = () => {
    const progressValues = Object.values(uploadProgress);
    return progressValues.length > 0 ? progressValues.reduce((a, b) => a + b) / progressValues.length : 0;
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6" gutterBottom>Documents</Typography>
      <input
        accept="application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        style={{ display: 'none' }}
        id="raised-button-file"
        type="file"
        multiple
        onChange={handleFileUpload}
      />
      <label htmlFor="raised-button-file">
        <Button variant="contained" component="span" startIcon={<Upload />} disabled={isUploading}>
          Upload Documents
        </Button>
      </label>
      {isUploading && (
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress variant="determinate" value={calculateOverallProgress()} />
        </Box>
      )}
      <List sx={{ mt: 2 }}>
        {documents.map((doc) => (
          <ListItem key={doc.id}>
            <ListItemText 
              primary={doc.name} 
              secondary={new Date(doc.timestamp.seconds * 1000).toLocaleString()} 
            />
            <Chip 
              label={doc.ingestion_status} 
              color={getStatusColor(doc.ingestion_status)} 
              size="small" 
              sx={{ mr: 1 }} 
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteDocument(doc)}>
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default DocumentSection;